@import "styles/utils";
@import "styles/components";

.sliderContainer{
  display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  @include phone() {
    height: auto;
    width:100%;
  }
}

.container{
  min-height: 80vh;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  position: relative;
  min-width:100vw;
  @include phone() {
    min-height:auto;
    height: auto;
    overflow: hidden;
  }


  .left{
    left: 10%;
  }
  .right{
    right: 10%;
  }

  .textContainer{
    z-index: 30;
    position: absolute;
    top: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include phone() {
      top:60%;
      max-width: 100%;
      width:100%;
      left:0;
      text-align: center;
    }


    .title {
      @include typography(3.125rem, 3.4rem);
      font-weight: bold;
      text-align: center;
      @include phone() {
        @include typography(2.5rem, 2.7rem);
        width: 100%;
        text-align: center;
      }
      @include phone-landscape() {
        font-size: 30px;
        left:0;
        width: 100%;
        text-align: center;
      }
    }

    .description {
      @include typography(1.4rem, 1.8rem);
      font-weight: lighter;
      text-align: center;
      @include phone() {
        margin-top:20px;
        @include typography(1.6rem, 1.8rem);
        width: 90%;
        @include paddingHorizontal(1rem);
        text-align: center;
      }
      @include phone-landscape() {
        top:80%;
        font-size: 15px;
        left:0;
        width: 100%;
        text-align: center;
      }
    }
  }

  .imageContainer {
    overflow: hidden;
    position:relative;
    min-height: 600px;
    width:100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    @include phone() {
      height: auto;
      min-height: auto;
      width:100%;
    }
  }
}

.browseButton {
  margin: 50px 0 0 0;
  padding: 14px 60px;
}
